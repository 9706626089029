import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Load Bootstrap CSS dynamically
    const bootstrapCSS = document.createElement('link');
    bootstrapCSS.rel = 'stylesheet';
    bootstrapCSS.href =
      'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.0-beta2/css/bootstrap.min.css';
    document.head.appendChild(bootstrapCSS);

    // Load auth.css dynamically
    const authCSS = document.createElement('link');
    authCSS.rel = 'stylesheet';
    authCSS.href = './auth.css';
    document.head.appendChild(authCSS);

    return () => {
      // Cleanup when the component is unmounted
      document.head.removeChild(bootstrapCSS);
      document.head.removeChild(authCSS);
    };
  }, []);

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    try {
      const response = await axios.post('http://tmpsd.itsquare.pt/api/login', { username, password });
      const token = response.data.token;

      // Store the token in local storage
      localStorage.setItem('token', token);
      console.log('Login successful!');
      navigate('/main');
    } catch (error) {
      const errorMessage = (error as any)?.response?.data?.error || 'Unknown error';
      console.error('Login failed:', errorMessage);
      setError(errorMessage);
    }
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h2 className="text-center text-dark mt-5">Sign In</h2>
          <div className="text-center mb-5 text-dark">Made by Mapu</div>
          <div className="card my-5">
            <form className="card-body cardbody-color p-lg-5" onSubmit={handleLogin}>
              <div className="text-center">
                <img src="./mapu.png" className="img-fluid profile-image-pic img-thumbnail rounded-circle my-3" width="200px" alt="profile" />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control" id="Username" aria-describedby="emailHelp" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
              </div>
              <div className="mb-3">
                <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-color px-5 mb-5 w-100">Login</button>
              </div>
              <div id="emailHelp" className="form-text text-center mb-5 text-dark">Not Registered? <button type="button" className="btn btn-link text-dark fw-bold" onClick={handleRegister}>Create an Account</button></div>
              {error && <p style={{ color: 'red' }}>{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
