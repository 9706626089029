import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    // Load Bootstrap CSS dynamically
    const bootstrapCSS = document.createElement('link');
    bootstrapCSS.rel = 'stylesheet';
    bootstrapCSS.href =
      'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.0-beta2/css/bootstrap.min.css';
    document.head.appendChild(bootstrapCSS);

    // Load auth.css dynamically
    const authCSS = document.createElement('link');
    authCSS.rel = 'stylesheet';
    authCSS.href = './auth.css';
    document.head.appendChild(authCSS);

    return () => {
      // Cleanup when the component is unmounted
      document.head.removeChild(bootstrapCSS);
      document.head.removeChild(authCSS);
    };
  }, []);

  const handleRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!firstName || !lastName || !username || !password || !confirmPassword) {
      setError('All fields are required');
      return;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(username)) {
      setError('Invalid email address');
      return;
    }

    // Check if the email ends with allowed domains
    if (!username.endsWith('@itsquare.pt') && !username.endsWith('@mapu.me')) {
      setError('Email registration is not authorized!');
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords don't match!");
      return;
    }

    try {
      const fullName = `${firstName} ${lastName}`;
      const response = await axios.post('http://tmpsd.itsquare.pt/api/register', {
        name: fullName,
        username,
        password,
      });
      console.log('Register successful! Token:', response.data.token);
      navigate('/login');
    } catch (error) {
      const errorMessage = (error as any)?.response?.data?.error || 'Unknown error';
      console.error('Register failed:', errorMessage);
      setError(errorMessage);
      // Change border color of confirm password input
      document.getElementById('confirmPassword')?.classList.add('border', 'border-danger');
    }
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <h2 className="text-center text-dark mt-5">Sign Up</h2>
          <div className="text-center mb-5 text-dark">Made by Mapu</div>
          <div className="card my-5">
            <form className="card-body cardbody-color p-lg-5" onSubmit={handleRegister}>
              <div className="mb-3">
                <input type="text" className="form-control" id="firstName" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
              </div>
              <div className="mb-3">
                <input type="text" className="form-control" id="lastName" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
              </div>
              <div className="mb-3">
                <input type="email" className="form-control" id="Username" aria-describedby="emailHelp" placeholder="Email" value={username} onChange={(e) => setUsername(e.target.value)} required />
              </div>
              <div className="mb-3 position-relative">
                <div className="input-group">
                  <input type={showPassword ? 'text' : 'password'} className="form-control" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  <button className="btn btn-outline-secondary" type="button" onClick={togglePasswordVisibility}>
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      className="password-toggle-icon"
                      style={{ cursor: 'pointer' }}
                    />
                  </button>
                </div>
              </div>
              <div className="mb-3 position-relative">
                <div className="input-group">
                  <input type={showConfirmPassword ? 'text' : 'password'} className="form-control" id="confirmPassword" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                  <button className="btn btn-outline-secondary" type="button" onClick={toggleConfirmPasswordVisibility}>
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEye : faEyeSlash}
                      className="password-toggle-icon"
                      style={{ cursor: 'pointer' }}
                    />
                  </button>
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-color px-5 mb-5 w-100">Create Account</button>
              </div>
              <div id="emailHelp" className="form-text text-center mb-5 text-dark">Already Registered? <button type="button" className="btn btn-link text-dark fw-bold" onClick={handleLogin}>Sign In</button></div>
              {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '-40px' }}>{error}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
