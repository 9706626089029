import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Load Bootstrap CSS dynamically
    const bootstrapCSS = document.createElement('link');
    bootstrapCSS.rel = 'stylesheet';
    bootstrapCSS.href =
      'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.0-beta2/css/bootstrap.min.css';
    document.head.appendChild(bootstrapCSS);

    // Load index.css dynamically
    const authCSS = document.createElement('link');
    authCSS.rel = 'stylesheet';
    authCSS.href = 'landing.css';
    document.head.appendChild(authCSS);

    // Load Google Fonts
    const googleFonts = document.createElement('link');
    googleFonts.rel = 'stylesheet';
    googleFonts.href = 'https://fonts.googleapis.com/css?family=Varela+Round|Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i';
    document.head.appendChild(googleFonts);

    return () => {
      // Cleanup when the component is unmounted
      document.head.removeChild(bootstrapCSS);
      document.head.removeChild(authCSS);
      document.head.removeChild(googleFonts);
    };
  }, []);

  const handleRegister = () => {
    navigate('/register');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div className="container px-4 px-lg-5">
          <a className="navbar-brand" href="/">
            <img src="mapulogo300.png" style={{ maxHeight: '72px', maxWidth: '100px' }} alt="MAPU Logo" />
          </a>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item"><button className="nav-link" onClick={handleLogin}>Login</button></li>
              <li className="nav-item"><button className="nav-link" onClick={handleRegister}>Sign Up</button></li>
            </ul>
          </div>
        </div>
      </nav>
      <header className="masthead">
        <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="text-center">
              <h1 className="mx-auto my-0 text-uppercase" style={{ fontFamily: 'Varela Round' }}>MAPU</h1>
              <h2 className="text-white-50 mx-auto mt-2 mb-5" style={{ fontFamily: 'Nunito' }}>Start here.</h2>
              <button className="btn btn-primary" onClick={handleLogin}>Login</button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Landing;