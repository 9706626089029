import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Navbar, Container, Nav, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import { JwtPayload } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const Main: React.FC = () => {
  axios.defaults.headers.post['Authorization'] = localStorage.getItem('token');
  const navigate = useNavigate(); // Initialize useNavigate hook to redirect
  const [user, setUser] = useState<string>(''); // Updated state to hold user's name
  const [contracts, setContracts] = useState<any[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRenewModal, setShowRenewModal] = useState<boolean>(false);
  const [showLogout, setShowLogout] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    customer: '',
    contractType: 'Micro',
    startDate: '',
    endDate: '',
    status: 'Active',
    amount: 0,
    amountWithVAT: 0,
  });
  const [deleteFormData, setDeleteFormData] = useState<any>({
    id: '',
    customer: '',
    customers: [],
  });
  const [renewFormData, setRenewFormData] = useState<any>({
    id: '',
    customer: '',
    contractType: 'Micro',
    startDate: '',
    endDate: '',
    status: 'Active',
    amount: 0,
    amountWithVAT: 0,
    customers: [],
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken: JwtPayload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = decodedToken.exp ? decodedToken.exp * 1000 : 0;
      const currentTime = Date.now();

      if (expirationTime && currentTime > expirationTime) {
        window.location.href = '/login';
      }
    } else {
      window.location.href = '/login';
    }

    const bootstrapCSS = document.createElement('link');
    bootstrapCSS.rel = 'stylesheet';
    bootstrapCSS.href = 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.0-beta2/css/bootstrap.min.css';
    document.head.appendChild(bootstrapCSS);

    // Fetch contracts and set contracts and total amount
    axios.post('http://tmpsd.itsquare.pt/api/contractsfetch')
      .then(response => {
        setContracts(response.data);
        const total = response.data.reduce((acc: number, contract: any) => acc + parseFloat(contract.amountWithVAT), 0); // Parse as float
        setTotalAmount(total);
  
        // Update delete and renew form data
        const customersWithIdName = response.data.map((contract: any) => ({
          id: contract.id,
          name: contract.customer,
        }));
        setDeleteFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
        setRenewFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
      })
      .catch(error => console.error('Error fetching contracts:', error));
  
    // Fetch user's name from the API
    axios.post('http://tmpsd.itsquare.pt/api/user')
      .then(response => {
        setUser(response.data.name); // Set the user's name in the state
      })
      .catch(error => {
        console.error('Error fetching user name:', error);
        // Handle errors accordingly
      });
  
    return () => {
      document.head.removeChild(bootstrapCSS);
    };
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseRenewModal = () => {
    setShowRenewModal(false);
  };

  const handleShowRenewModal = () => {
    setShowRenewModal(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleDeleteChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setDeleteFormData({ ...deleteFormData, [name]: value });
  };
  
  const handleRenewChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setRenewFormData({ ...renewFormData, [name]: value });
  };

  const handleSaveContract = () => {
    // Calculate amountWithVAT based on the selected VAT percent
    const vatPercent = parseInt(formData.vat); // Parse the VAT percent to integer
    const amountWithVAT = (formData.amount * (1 + vatPercent / 100)).toFixed(2); // Round to 2 decimal points
  
    // Prepare the contract data to be saved
    const contractData = {
      ...formData,
      amountWithVAT: amountWithVAT, // Set the calculated amount with VAT
    };
  
    axios.post('http://tmpsd.itsquare.pt/api/contractsadd', contractData)
      .then(response => {
        console.log('Contract saved successfully:', response.data);
        axios.post('http://tmpsd.itsquare.pt/api/contractsfetch')
          .then(response => {
            setContracts(response.data);
            const total = response.data.reduce((acc: number, contract: any) => acc + parseFloat(contract.amountWithVAT), 0); // Parse as float
            setTotalAmount(total);
  
            // Update delete and renew form data
            const customersWithIdName = response.data.map((contract: any) => ({
              id: contract.id,
              name: contract.customer,
            }));
            setDeleteFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
            setRenewFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
          })
          .catch(error => console.error('Error fetching contracts:', error));
      })
      .catch(error => console.error('Error saving contract:', error));
    setShowModal(false);
  };

  const handleDeleteContract = () => {
    // Extract the customer ID from deleteFormData
    const customerId = deleteFormData.customer;
    
    // Prepare the data to be sent, following the {"id": `id`} format
    const requestData = {
      id: customerId
    };
    
    axios.post('http://tmpsd.itsquare.pt/api/contractsdelete', requestData)
      .then(response => {
        console.log('Contract deleted successfully:', response.data);
        axios.post('http://tmpsd.itsquare.pt/api/contractsfetch')
          .then(response => {
            setContracts(response.data);
            const total = response.data.reduce((acc: number, contract: any) => acc + parseFloat(contract.amountWithVAT), 0); // Parse as float
            setTotalAmount(total);
  
            // Update delete and renew form data
            const customersWithIdName = response.data.map((contract: any) => ({
              id: contract.id,
              name: contract.customer,
            }));
            console.log('Before update:', deleteFormData.customers); // Log before update
            setDeleteFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
            setRenewFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
            console.log('After update:', customersWithIdName); // Log after update
          })
          .catch(error => console.error('Error fetching contracts:', error));
      })
      .catch(error => console.error('Error deleting contract:', error));
    setShowDeleteModal(false);
  };

  const handleRenewContract = () => {
    // Extract the customer ID from renewFormData
    const renewCustomerId = renewFormData.customer;
    const renewContractType = renewFormData.contractType;
    const renewStartDate = renewFormData.startDate;
    const renewEndDate = renewFormData.endDate;
    const renewStatus = renewFormData.status;
    const renewAmount = renewFormData.amount;
    // Calculate amountWithVAT based on the selected VAT percent
    const vatPercent = parseInt(renewFormData.vat); // Parse the VAT percent to integer
    const amountWithVAT = (renewFormData.amount * (1 + vatPercent / 100)).toFixed(2); // Round to 2 decimal points
    
    // Prepare the data to be sent, following the {"id": `id`} format
    const requestData = {
      id: renewCustomerId,
      contractType: renewContractType,
      startDate: renewStartDate,
      endDate: renewEndDate,
      status: renewStatus,
      amount: renewAmount,
      amountWithVAT: amountWithVAT
    };
    
    axios.post('http://tmpsd.itsquare.pt/api/contractsrenew', requestData)
      .then(response => {
        console.log('Contract deleted successfully:', response.data);
        axios.post('http://tmpsd.itsquare.pt/api/contractsfetch')
          .then(response => {
            setContracts(response.data);
            const total = response.data.reduce((acc: number, contract: any) => acc + parseFloat(contract.amountWithVAT), 0); // Parse as float
            setTotalAmount(total);
  
            // Update delete and renew form data
            const customersWithIdName = response.data.map((contract: any) => ({
              id: contract.id,
              name: contract.customer,
            }));
            console.log('Before update:', renewFormData.customers); // Log before update
            setDeleteFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
            setRenewFormData((prevData: any) => ({ ...prevData, customers: customersWithIdName }));
            console.log('After update:', customersWithIdName); // Log after update
          })
          .catch(error => console.error('Error fetching contracts:', error));
      })
      .catch(error => console.error('Error renewing contract:', error));
    setShowRenewModal(false);
  };

  const handleLogout = () => {
    // Clear the token from local storage
    localStorage.removeItem('token');

    // Redirect to the login page
    navigate('/login');
  };

  return (
    <div>
    <Navbar style={{ backgroundColor: '#f2f2f2' }}>
      <Container>
        <Navbar.Brand href="#home">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/mapu.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Logo"
              style={{ backgroundColor: 'transparent', border: 'none' }}
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={handleShowModal}>Add Contract</Nav.Link>
            <Nav.Link onClick={handleShowDeleteModal}>Delete Contract</Nav.Link>
            <Nav.Link onClick={handleShowRenewModal}>Renew/Edit Contract</Nav.Link>
          </Nav>
          <div className="d-flex align-items-center">
            <div className="position-relative">
              <div className="dropdown" onMouseEnter={() => setShowLogout(true)} onMouseLeave={() => setShowLogout(false)}>
                <Button
                  variant="light"
                  style={{ marginRight: '10px' }}
                  id="dropdown-basic"
                >
                  <div
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      background: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {/* Display user's initials */}
                    {user.split(' ').map(name => name[0]).join('')}
                  </div>
                </Button>
                <Dropdown show={showLogout} align="end" onMouseEnter={() => setShowLogout(true)} onMouseLeave={() => setShowLogout(false)}>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* Display user's name */}
            <span style={{ marginRight: '10px' }}>{user}</span>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
      <br></br>

      <div className="container">
        <div>
          <h3>Total: {totalAmount} Euro</h3>
        </div>
        <br></br>

        {contracts.length > -1 && (
          <table className="table">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Contract Type</th>
                <th>Contract Start Date</th>
                <th>Contract End Date</th>
                <th>Contract Status</th>
                <th>Contract Amount</th>
                <th>Contract Amount + VAT</th>
              </tr>
            </thead>
            <tbody>
              {contracts.map((contract) => (
                <tr key={contract.id}>
                  <td>{contract.customer}</td>
                  <td>{contract.contractType}</td>
                  <td>{contract.startDate}</td>
                  <td>{contract.endDate}</td>
                  <td>{contract.status}</td>
                  <td>{contract.amount} Euro</td>
                  <td>{contract.amountWithVAT} Euro</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Adding contract modal here */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="customer">
                <Form.Label>Customer</Form.Label>
                  <Form.Control
                    type="text"
                    name="customer"
                    value={formData.customer}
                    onChange={handleChange}
                  />
              </Form.Group>
              <Form.Group controlId="contractType">
                <Form.Label>Contract Type</Form.Label>
                <Form.Select
                  name="contractType"
                  value={formData.contractType}
                  onChange={handleChange}
                >
                  <option value="VIP">VIP</option>
                  <option value="Micro">Micro</option>
                  <option value="Pro">Pro</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="startDate">
                <Form.Label>Contract Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>Contract End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="status">
                <Form.Label>Contract Status</Form.Label>
                <Form.Select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                >
                  <option value="Active">Active</option>
                  <option value="Suspended">Suspended</option>
                  <option value="Ended">Ended</option>
                  <option value="In Progress">In Progress</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="amount">
                <Form.Label>Contract Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="amountWithVAT">
                <Form.Label>VAT Percent</Form.Label>
                <Form.Select
                  name="vat"
                  value={formData.vat}
                  onChange={handleChange}
                >
                  <option value="0">0%</option>
                  <option value="23">23%</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveContract}>
              Save Contract
            </Button>
          </Modal.Footer>
        </Modal>


        {/* Renew or Edit contract modal here */}
        <Modal show={showRenewModal} onHide={handleCloseRenewModal}>
          <Modal.Header closeButton>
            <Modal.Title>Renew/Edit Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="customerToDelete">
                <Form.Label>Customer to Renew/Edit</Form.Label>
                <Form.Control
                  as="select"
                  name="customer"
                  value={renewFormData.customer}
                  onChange={handleRenewChange}
                >
                  <option value="">Select a customer</option> {/* Empty option */}
                  {renewFormData.customers &&
                    renewFormData.customers.map((customer: any, index: number) => (
                      <option key={index} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="contractType">
                <Form.Label>Contract Type</Form.Label>
                <Form.Select
                  name="contractType"
                  value={renewFormData.contractType}
                  onChange={handleRenewChange}
                >
                  <option value="VIP">VIP</option>
                  <option value="Micro">Micro</option>
                  <option value="Pro">Pro</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="startDate">
                <Form.Label>Contract Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={renewFormData.startDate}
                  onChange={handleRenewChange}
                />
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>Contract End Date</Form.Label>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={renewFormData.endDate}
                  onChange={handleRenewChange}
                />
              </Form.Group>
              <Form.Group controlId="status">
                <Form.Label>Contract Status</Form.Label>
                <Form.Select
                  name="status"
                  value={renewFormData.status}
                  onChange={handleRenewChange}
                >
                  <option value="Active">Active</option>
                  <option value="Suspended">Suspended</option>
                  <option value="Ended">Ended</option>
                  <option value="In Progress">In Progress</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="amount">
                <Form.Label>Contract Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={renewFormData.amount}
                  onChange={handleRenewChange}
                />
              </Form.Group>
              <Form.Group controlId="amountWithVAT">
                <Form.Label>VAT Percent</Form.Label>
                <Form.Select
                  name="vat"
                  value={renewFormData.vat}
                  onChange={handleRenewChange}
                >
                  <option value="0">0%</option>
                  <option value="23">23%</option>
                </Form.Select>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseRenewModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleRenewContract}>
              Update Contract
            </Button>
          </Modal.Footer>
        </Modal>


        {/* Delete contract modal here */}
        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
            <Form.Group controlId="customerToDelete">
              <Form.Label>Customer to Delete</Form.Label>
              <Form.Control
                as="select"
                name="customer"
                value={deleteFormData.customer}
                onChange={handleDeleteChange}
              >
                <option value="">Select a customer</option> {/* Empty option */}
                {deleteFormData.customers &&
                  deleteFormData.customers.map((customer: any, index: number) => (
                    <option key={index} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Close
            </Button>
            <Button variant="danger" onClick={handleDeleteContract}>
              Delete Contract
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Main;
